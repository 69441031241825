.list_container_wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #0000ff;
  z-index: 1;
}
