.tabs_link_wraper {
  padding: 1rem 0 1rem 1rem;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  justify-content: center;
  font-size: 1.1rem;
  margin: 2px 0;
}
.tabs_link_wraper:hover:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #fd03dc;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  animation: tabs_link_hover 0.4s ease-in;
}
.tabs_link_active::after {
  content: "";
  width: 3px;
  height: 100%;
  background: #fd03dc;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes tabs_link_hover {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}

.tab_open_container_wraper {
  height: 100%;
  width: 100%;
  padding: 2rem 5rem 2rem 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  animation: tab_open_container_wraper 0.5s ease-in-out;
  overflow-y: auto;
}

@keyframes tab_open_container_wraper {
  0% {
    top: -85vh;
  }
  100% {
    top: 0;
  }
}
.tab_close_container_wraper {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -100%;
  z-index: -1;
  animation: tab_close_container_wraper 0.5s ease-in-out;
  /* display: none; */
}
@keyframes tab_close_container_wraper {
  0% {
    bottom: 0;
    z-index: 1;
  }
  100% {
    bottom: -100%;
    display: none;
    z-index: -2;
  }
}

.form_hading {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.6rem 0;
  padding-left: 1rem;
  position: relative;
}
.form_hading::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  top: 0;
  left: 0;
  background: #fd03dc;
}

#whatsapp_number::-webkit-inner-spin-button,
#whatsapp_number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form_submit_btn {
  color: #fff;
  padding: 1rem 1.3rem;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  background: #fd03dc !important;
  transition: all 0.33s ease-in-out;
  border: 1px solid red;
}

.form_submit_btn:hover {
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.25);
}
