.setting_link_wraper {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0 0.5rem 1rem;
  display: block;
  font-size: 1rem;
  font-weight: 500;
}

.setting_link_wraper::before {
  content: "";
  width: 3px;
  height: 100%;
  background: #e5e7eb;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.setting_link_wraper:hover:after {
  content: "";
  width: 3px;
  height: 90%;
  position: absolute;
  background: #fd03dc;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  animation: setting_link_animation 0.4s ease-in;
  z-index: 1;
}
.setting_link_acctive:after {
  content: "";
  width: 3px;
  height: 90%;
  position: absolute;
  background: #fd03dc;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  animation: setting_link_animation 0.4s ease-in;
  z-index: 1;
}

@keyframes setting_link_animation {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 90%;
    opacity: 1;
  }
}
