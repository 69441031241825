.gradient-pink-purple-95 {
  background: linear-gradient(
    0deg,
    rgba(244, 64, 217, 0.9220063025210083) 17%,
    rgba(178, 66, 217, 1) 95%
  );
}
.wt-videoholder {
  float: left;
  width: 100%;
}
.wt-videoshow {
  float: left;
  width: auto;
  margin-right: 20px;
}
.wt-videoshow a:after {
  top: -5px;
  left: -5px;
  content: "";
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.7);
  animation: sonarWave 1.5s linear infinite;
}

/* From https://css.glass */
.glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border: 1px solid rgba(255, 255, 255, 0.24);
}

@keyframes sonarWave {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.jodit-status-bar-link {
  display: none;
}
