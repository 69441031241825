.form_submit_btn {
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  background: #fd03dc !important;
  transition: all 0.33s ease-in-out;
  border: 1px solid red;
}

.form_submit_btn:hover {
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.25);
}
