.Placeorder {
    font-size: 14px;
}

.Placeorder .order {
    border-radius: 5px;
}

.Placeorder .shop_table {
    width: 100%;
    border-collapse: collapse;
    /* margin-bottom: 20px; */
}


.Placeorder .shop_table tbody td {
    border-bottom: 1px solid #ddd;
    padding: 10px;
}

.Placeorder .shop_table .product-name {
    width: 70%;
}

.Placeorder .shop_table .product-total {
    width: 30%;
    text-align: right;
}

.wc_payment_methods {
    background-color: #ebe9eb;
    padding: 20px;
    font-size: 10px;
    margin-top: 40px;
}

.payment_method_ccavenue {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    /* color: #b6babd; */
}

.payment_box {
    /* display: flex; */
    background-color: #dfdcde;
    font-size: 10px;
    padding: 10px;
    border-radius: 5px;
    border-bottom: 2px solid #b6babd;
    margin-bottom: 10px;
}

.privacy-policy-text {
    width: 100%;
}

#button {
    background-color: #fe5f88;
    padding: 10px;
    position: relative;
    text-align: center;
    cursor: pointer;
    color: rgb(71, 68, 68);
    margin-top: 10px;
}

.payment-success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #f8f9fa;
}

.payment-success-content {
    text-align: center;
    padding: 10px 100px;
    border: 2px solid black;
}

.payment-success-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #007bff;
}

.payment-success-message {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #343a40;
}

.payment-success-icon {
    width: 30px;
    height: 30px;
}