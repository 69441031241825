.sm_navbar_menu_wraper {
  position: fixed;
  width: 250px;
  height: 100vh;
  border: 1px solid red;
  top: 0;
  left: 0;
  animation: mobileMenu 1s ease-in-out;
}

.sm_navbar_menu_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  animation: mobileMenu 1s ease-in-out;
  color: aliceblue;
  overflow: auto;
  padding-left: 1.5rem;
}
.sm_navbar_menu_container::-webkit-scrollbar {
  display: none;
}

@keyframes mobileMenu {
  0% {
    opacity: 0;
    left: -205px;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}

.menu_container_wrapper .drop_down_link_wrapper {
  padding: 10px 10px;
  border-radius: 7px;
  transition: all 0.5s ease-in-out;
  position: relative;
  cursor: pointer;
  margin: 0 0.2rem !important;
}
.nav_bar_link_btn {
  margin: 0 0.5rem !important;
}

.menu_container_wrapper .drop_down_link_wrapper:hover {
  background: #f240d2, #bb41d7;
  background: linear-gradient(rgba(242, 64, 210, 0.6), rgba(187, 65, 215, 0.6));
}
.menu_container_wrapper .drop_down_link_wrapper .active {
  background: #f240d2, #bb41d7;
  background: linear-gradient(rgba(242, 64, 210, 0.6), rgba(187, 65, 215, 0.6));
}
.menu_container_wrapper
  .drop_down_link_wrapper
  .drop_down_menu_container_wrapper {
  position: absolute;
  width: 260px;
  top: 100%;
  left: 0;
  background: transparent;
  padding: 1rem 0;
  border-radius: 3px;
  display: none;
  animation: dropdownAnimation 0.5s ease-in-out;
}
.menu_container_wrapper .drop_down_link_wrapper .drop_down_menu_wrapper {
  background: #eaeaea;
  padding: 1rem 0;
  border-radius: 0.3rem;
  position: relative;
}
.menu_container_wrapper
  .drop_down_link_wrapper
  .drop_down_menu_wrapper::before {
  content: "";
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  border-top: none;
  border-right: 0.8rem solid transparent;
  border-left: 0.8rem solid transparent;
  border-bottom: 0.8rem solid #eeeeee;
}
@keyframes dropdownAnimation {
  0% {
    margin-top: -10px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
}
.menu_container_wrapper .drop_down_link_wrapper .drop_down_menu_wrapper li a {
  margin: 2px 0;
  padding: 5px 2rem;
  transition: all 0.5s ease-in-out;
  display: inline-block;
  width: 100%;
}
.menu_container_wrapper
  .drop_down_link_wrapper
  .drop_down_menu_wrapper
  li
  a:hover {
  background: #0000004d;
}
.menu_container_wrapper
  .drop_down_link_wrapper
  .drop_down_menu_wrapper
  li
  .active {
  background: #0000004d;
}
.menu_container_wrapper
  .drop_down_link_wrapper:hover
  .drop_down_menu_container_wrapper {
  display: block;
}
.menu_container_wrapper .drop_down_link_wrapper span {
  font-weight: bold;
  font-size: 1.1rem;
}

.mobile_menu_content_wraper .drop_down_link_wrapper .drop_down_menu_wrapper {
  padding-left: 5px;
  padding-top: 15px;
}
.mobile_menu_content_wraper
  .drop_down_link_wrapper
  .drop_down_menu_wrapper
  li
  a {
  padding: 0.3rem;
  margin: 2px 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #000;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
  color: #eaeaea;
  transition: all 0.5s ease-in;
}

.mobile_menu_content_wraper
  .drop_down_link_wrapper
  .drop_down_menu_wrapper
  li
  a:hovers {
  background: #eaeaea;
  color: #000;
}
.mobile_menu_content_wraper
  .drop_down_link_wrapper
  .drop_down_menu_wrapper
  li
  a
  .active {
  background: #eaeaea;
  color: #000;
}

@media screen and (max-width: 400px) {
  .sm_navbar_menu_wraper,
  .sm_navbar_menu_container {
    width: 100%;
  }
}
