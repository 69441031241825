.user_profile_link {
  padding: 0.5rem 0 0.5rem 1rem;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  justify-content: center;
  font-size: 1.1rem;
  margin: 2px 0;
  font-weight: 600;
}
.user_profile_link:hover:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #fd03dc;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  animation: link_active_animation 0.4s ease-in;
}
.user_profile_link_active::after {
  content: "";
  width: 3px;
  height: 100%;
  background: #fd03dc;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes link_active_animation {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
