.checkout {
    width: 100%;
    overflow: hidden;
    background-color: white;
}

.ordodd {
    text-align: center;
    align-items: center;
    color: rgb(0, 0, 0);
    margin-top: 10px;
    font-weight: bolder;
}

.outer-container {
    margin: 30px 170px;
    display: flex;
    gap: 50px;
    flex-direction: row;
}

.inner-container-1 {
    flex: 1;
}

.inner-container-2 {
    flex: 1;
}

textarea {
    height: 150px;
}

.title {
    border-bottom: 2px solid rgb(171, 167, 167);
    margin-bottom: 30px;
}

label {
    font-size: 14px;
}