/* home page background style start*/
.bg {
  background-image: url("https://work.elite-professionals.in/wp-content/uploads/2022/05/sample-01-1.jpg");
}
/* home page background style end */

/* home page Categories */
/* .category-bg {
  background-image: url("./category_bg.svg"),
    linear-gradient(
      to top,
      rgba(147, 76, 255, 1) 0%,
      rgba(246, 43, 132, 1) 100%
    );
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  width: 100%;
} */
/*
home page testimonials
*/

/* <<=======================================================>>
  comon css start
<<=======================================================>> */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* <<=======================================================>>
  comon css End
<<=======================================================>> */

/* ==================================
  tooltip custom css start
================================== */
/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  min-width: 80px;
  height: 1.5rem;
  top: -100%;
  left: 110%;
  padding: 0.3rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #f0ebeb;
  color: #000;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 12px;
  line-height: 1.2;
  -webkit-box-shadow: -1px 6.5px 11px -10px #dddddd;
  -moz-box-shadow: -1px 6.5px 11px -10px #dddddd;
  box-shadow: -1px 6.5px 11px -10px #dddddd;
}

/* Triangle hack to make tooltip look like a speech bubble */
/* [data-tooltip]:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  top: 3px;
  left: 112%;
  border-top: 0.4rem solid #f0ebeb;
  border-right: 0.4rem solid transparent;
  border-left: 0.4rem solid transparent;
} */

/* Show tooltip content on hover */
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* ==================================
  tooltip custom css End
================================== */

.arrow {
  top: 97%;
  left: 0px;
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 30px solid transparent;
  border-top: 40px solid #fff;
  margin: 0;
  position: absolute;
}

/* home page freelancer review */
.wt-freelancer {
  position: relative;
}
.wt-freelancer:before {
  top: 0;
  right: 0;
  width: 200px;
  content: "";
  background-image: url(https://work.elite-professionals.in/wp-content/themes/workreap/images/sec-bg-freelancer.png);
  position: absolute;
  height: 100%;
  opacity: 0.08;
}

/* home banner */
.elementor-944
  .elementor-element.elementor-element-657c534
  > .elementor-background-overlay {
  /* background-image: url(); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

/* file section */
.file_select_btn {
  background: #00cc8d;
  cursor: pointer;
  color: #fff;
  padding: 1rem 50px;
  text-align: center;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.file_select_btn:hover {
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.25);
}

/* =============== My Profile ==================== */

/* .user_profile_wraper{
    position: relative;
  } */
.user_profile_wraper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #e0f7fa;
  width: 100%;
  height: 2rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

/* footer css */
.footer_bg {
  background-image: url(https://work.elite-professionals.in/wp-content/themes/workreap/images/homeseven/footer-bg2.png),
    linear-gradient(
      to top,
      rgba(147, 76, 255, 1) 0%,
      rgba(246, 43, 132, 1) 100%
    );
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  width: 100%;
}

/* footer link hover */
.transition {
  text-decoration: none !important;
}
.transition:hover {
  margin-left: 10px;
  transition: all 300ms ease-in-out;
  background-color: inherit;
  text-decoration: none;
}
/*
<<=======================================================>>
view job invite freelancer All proposal hire css start
<<=======================================================>>

*/
.submenu_container_wrapper {
  position: relative;
}
.submenu_top_right_arrow::before {
  content: "";
  position: absolute;
  top: -0.8rem;
  left: 0.3rem;
  border-top: none;
  border-right: 0.8rem solid transparent;
  border-left: 0.8rem solid transparent;
  border-bottom: 0.8rem solid #eeeeee;
}
.submenu_top_left_arrow::before {
  content: "";
  position: absolute;
  top: -0.8rem;
  right: 0.3rem;
  border-top: none;
  border-right: 0.8rem solid transparent;
  border-left: 0.8rem solid transparent;
  border-bottom: 0.8rem solid #eeeeee;
}
.earnings_list_wrapper {
  position: relative;
}
.earnings_list_wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: black;
}
/*
<<=======================================================>>
view job invite freelancer All proposal hire css End
<<=======================================================>>

<<=======================================================>>
freelancer My jobs route css start
<<=======================================================>>
*/
.my_job_menu_list {
  position: relative;
}
.my_job_menu_list::before {
  content: "";
  position: absolute;
  bottom: -0.1rem;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background: #fd03dc;
}

/*
<<=======================================================>>
freelancer My jobs route css end
<<=======================================================>>
<<=======================================================>>
Accordion css start
<<=======================================================>>
*/

.accordion .container {
  position: relative;
}

.accordion .label {
  position: relative;
  cursor: pointer;
}

.accordion .label::before {
  content: "+";
  color: black;
  position: absolute;
  top: 50%;
  right: 0.5rem;
  font-size: 30px;
  transform: translateY(-50%);
}

.accordion .content {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
}

.accordion .container.active .content {
  height: 12rem;
  overflow-y: scroll;
}

/* Changes from plus sign to negative sign once active */
.accordion .container.active .label::before {
  content: "-";
  font-size: 30px;
}

/* <<=======================================================>>
  Accordion css End
<<=======================================================>> */

/* .bg {
  background-image: url("https://work.elite-professionals.in/wp-content/uploads/2022/05/sample-01-1.jpg");
} */

/* custom breakpoint */
@media only screen and (max-width: 800px) {
  .bg {
    background-image: none;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bg {
    background-image: url("https://work.elite-professionals.in/wp-content/uploads/2022/05/sample-01-1.jpg");
  }
}
