.form_hading {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.6rem 0;
  padding-left: 1rem;
  position: relative;
}
.form_hading::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  top: 0;
  left: 0;
  background: #fd03dc;
}

.form_submit_btn {
  color: #fff;
  padding: 1rem 1.3rem;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  background: #fd03dc !important;
  transition: all 0.33s ease-in-out;
  border: 1px solid red;
}

.form_submit_btn:hover {
  box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, 0.25);
}
